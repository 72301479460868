import draggable from 'vuedraggable'
import GroupComponent from '../../group/GroupComponent/index.vue'
import DashboardGridLayout from "../DashboardGrid/index.vue"

export default {
	components: {
		draggable,
		DashboardGridLayout,
		GroupComponent
	},

	props: {
		id: {
			type: [String, Number],
			default: 0,
		},
		fullscreen: {
			type: Boolean,
			default: false,
			required: false,
		},
		zoom: {
			type: Number,
			default: 100,
			required: false,
		},

		initialDashboardDisplay: {
			required:false,
			default: 0,
			type: Number
		}
	},

	data() {
		return {
			time: '',
			zoomLevel: 100,
			dashboard: new this.$dashboard(),
			groups: [],
			varGroupKey: undefined,
			variables: { message: [] },
			isFullscreen: false,
			isMouseMoving:false,
			groupsKey: 0,
			isShowingAllIcons: true,
			measurer: 0,
			isLoading: true,
			refreshGrid: false,

			activeAlarmList: [],
			activeAlarmAudioInterval: 0,

			dashboardDisplayList: [],
			selectedDashboardDisplayId: 'default',
			dashboardDisplayBeingUpdated: -1,
			isDashboardDisplayListOpen: false,

			dataValues: []

		}
	},

	computed: {
		rowHeight(){
			var multiplier = (1 - (this.zoomLevel/100)) + 1
            return 360 * (multiplier*1.35)
		},
		allowEditing() {
			return this.$session.get("user").privilege == 2 || this.$session.get("isMobile")
		},
		zoomInDisabled() {
			if (this.zoomLevel == 150) {
				return {
					"--cursor": "not-allowed",
					"--color": "#ffffff80"
				}
			}
			else {
				return {
					"--cursor": "pointer",
					"--color": "white"
				}
			}
		},
		zoomOutDisabled() {
			if (this.zoomLevel == 25) {
				return {
					"--cursor": "not-allowed",
					"--color": "#ffffff80"
				}
			}
			else {
				return {
					"--cursor": "pointer",
					"--color": "white"
				}
			}
		},
		alarmColors() {
			const { attentionColor, informColor, criticalColor } = this.dashboard
			return { attention: attentionColor, inform: informColor, critical: criticalColor }
		},
		hasDashboardDisplay(){
			return this.dashboardDisplayList.length > 0
		},
		selectedDisplayStyle(){
			if(this.hasDashboardDisplay && this.isFullscreen){
				const info = this.dashboardDisplayList.find(item => item.id == this.selectedDashboardDisplayId)
	
				let {gridTemplateRows, gridTemplateAreas, scale} = this.getGrid(info)
	
				return {
					'--num-of-cols': info.columns,
					'grid-template-rows': gridTemplateRows,
					'grid-template-areas': gridTemplateAreas,
					'zoom': scale,
				}
			}

			return {}

		},
		groupsToShow(){
			var selected = this.dashboardDisplayList.find(item => item.id == this.selectedDashboardDisplayId)
			if(selected){
				var groupsDisplay = selected.groupsDisplays.map(g => g.group.id)
				return this.groups.filter(g => groupsDisplay.includes(g.id))
			}
			return this.groups
		}
	},

	methods: {

		//Dashboard Display =============
		adjustScreen(){
			const container = document.querySelector("#dashboardArea")

			this.zoomLevel = window.innerHeight / container.scrollHeight * 100 
		},
		getNumberOfCols(group){
			if(this.hasDashboardDisplay && this.isFullscreen && this.selectedDashboardDisplayId != 'default'){

				const info = this.dashboardDisplayList.find(item => item.id == this.selectedDashboardDisplayId)
				var groupEl = info.groupsDisplays.find(g => g.group?.id == group.id)

				if(groupEl) return groupEl.w
				else return 6
			}
			return 6
		},
		getNumberOfRows(group){
			if(this.hasDashboardDisplay && this.isFullscreen && this.selectedDashboardDisplayId != 'default'){
				const info = this.dashboardDisplayList.find(item => item.id == this.selectedDashboardDisplayId)
				var groupEl = info.groupsDisplays.find(g => g.group?.id == group.id)
				return groupEl.h
			}
			return undefined
		},
		getGroupPosition(group){
			if(this.hasDashboardDisplay && this.isFullscreen){
				return {'grid-area': this.replaceSpecialChar(group.name)}
			}
			return ''
		},
		replaceSpecialChar(str) {
            // Remove os acentos e substitui por caracteres simples
            if (str.search(/[\xC0-\xFF]/g) > -1) {
              str = str
                .replace(/[\xC0-\xC5]/g, "A")
                .replace(/[\xC6]/g, "AE")
                .replace(/[\xC7]/g, "C")
                .replace(/[\xC8-\xCB]/g, "E")
                .replace(/[\xCC-\xCF]/g, "I")
                .replace(/[\xD0]/g, "D")
                .replace(/[\xD1]/g, "N")
                .replace(/[\xD2-\xD6\xD8]/g, "O")
                .replace(/[\xD9-\xDC]/g, "U")
                .replace(/[\xDD]/g, "Y")
                .replace(/[\xDE]/g, "P")
                .replace(/[\xE0-\xE5]/g, "a")
                .replace(/[\xE6]/g, "ae")
                .replace(/[\xE7]/g, "c")
                .replace(/[\xE8-\xEB]/g, "e")
                .replace(/[\xEC-\xEF]/g, "i")
                .replace(/[\xF1]/g, "n")
                .replace(/[\xF2-\xF6\xF8]/g, "o")
                .replace(/[\xF9-\xFC]/g, "u")
                .replace(/[\xFE]/g, "p")
                .replace(/[\xFD\xFF]/g, "y");
            }
            return str.replace(/[^A-Z0-9]+/ig, "");
        },
		selectDashboardDisplay(id){
			this.selectedDashboardDisplayId = id
			const info = this.dashboardDisplayList.find(item => item.id == this.selectedDashboardDisplayId)
			this.zoomLevel = info.zoom * 100
		},
		refreshDashboardDisplay(id){
			if(this.dashboardDisplayBeingUpdated != -1) return
			this.dashboardDisplayBeingUpdated = id

			this.dashboardDisplayService.search(id).then(res => {
				res.zoom = res.zoom / 100

				let displayIndex = this.dashboardDisplayList.findIndex(el => el.id === res.id)
				if(displayIndex != -1){
					this.selectDashboardDisplay('default')
					this.$nextTick(()=>{
						const oldDashboardDisplay = this.dashboardDisplayList[displayIndex]
						let updatedGroupDisplay = this.updateGroupDisplay(res.groupsDisplays, oldDashboardDisplay.groupsDisplays)
						res.dashboard = oldDashboardDisplay.dashboard
						res.groupsDisplays = updatedGroupDisplay
						this.dashboardDisplayList[displayIndex] = res
						this.selectDashboardDisplay(id)
						this.dashboardDisplayBeingUpdated = -1
					})
				}
			})
		},
		updateGroupDisplay(groupDisplay, oldGroupDisplay){
			let updateGroupDisplayList = []
			for (let display of groupDisplay){
				let oldDisplay = oldGroupDisplay.find(d => d.id === display.id)
				if(oldDisplay){
					const { group } = oldDisplay
					updateGroupDisplayList.push({...display, group})
				}
			}

			return updateGroupDisplayList
		},
		generateGridArea(areas){
			let gridTemplateAreas = "";
			let maxY = Math.max(...areas.map(area => area.y + area.h));
			let dashboardDisplayCols = this.dashboardDisplayList.find(item => item.id == this.selectedDashboardDisplayId).columns
			for (let i = 0; i < maxY; i++) {
				let row = "";
				for (let j = 0; j < dashboardDisplayCols; j++) {
					let foundArea = areas.find(area => area.y <= i && i < area.y + area.h && area.x === j);
					if (foundArea) {
						row += `${Array(foundArea.w).fill(foundArea.groupName).join(" ")} `
						j += foundArea.w - 1;
					} else {
						row += ". ";
					}
				}
				gridTemplateAreas += `"${row.trim()}" `;
			}
			return gridTemplateAreas.trim();
		},
		getGrid(display){
			var groups = display.groupsDisplays.map(g => {
				let groupEl = this.groups.find(group => group.id == g.group.id)
				if(groupEl) return {...g, groupName: this.replaceSpecialChar(groupEl.name)}
				else return {...g, groupName: "Grupo"}
			})

			let gridArea = this.generateGridArea(groups)

			
			let gridTemplateRows = `${this.rowHeight}px`
			let gridTemplateAreas = gridArea


			return {gridTemplateRows, gridTemplateAreas, scale: this.zoomLevel/100}
		},
		getNextLine(list){
			let maxY = -Infinity
			let maxH = -Infinity

			list.forEach(item => {
				if(item.y > maxY){
					maxY = item.y
					maxH = item.h
				}
				if(item.h == maxH && item.h > maxH){
					maxH = item.h
				}
			})
			return maxY + maxH
		},

		// =============

		getTime(){
			this.time = new Date().toString().split(" ")[4]
		},
		gridChange(measurerList, id) { // Mostrar icone de sincronizar no cabeçalho do grupo quando muda a posição dos medidores no grid
			this.$refs[`Group-${id}`][0].gridChange(measurerList)
		},

		//Requisições =============


		async editMeasurer(measurer) {
			if(this.isFullscreen){
				this.exitFullScreen()
				await this.$nextTick()
			}
			this.$refs.forms.openEdit({
				"component": 'dashboard-measurer-info',
				"element": measurer
			})
		},
		addElement(requisition) {
			const { value } = requisition
			if (value != undefined) {
				this.dashboard.groups.push(value);
			}

			this.service
				.update(this.dashboard, this.$session.get("logged_id"))
				.then((el) => {
					this.reloadItems();
				});

		},
		async updatePosition(){
			this.refreshGrid = true;
			await this.$nextTick()
			this.refreshGrid = false
		},
		updateElement(requisition){
			this.service
				.update(this.dashboard, this.$session.get("logged_id"))
				.then((el) => {
					this.reloadItems();
				});
		},
		showModal(measurer) { // Mostra Modal de deleção
			this.measurer = measurer;
			this.$bvModal.show('modalConfirmDelete');
		},
		deleteCard() { // Confirma deleção, chama método confirDelete de DashboardGridLayout

			if (this.measurer.id != undefined && this.measurer.id > 0) {
				this.measurerService
					.erase(this.measurer, this.$session.get('logged_id'))
					.then(() => {
						this.reloadItems();
					},
						(err) => console.log(err))
			}

			this.$bvModal.hide('modalConfirmDelete')
		},
		editCard() {
			this.$bvModal.hide('modalConfirmDelete')
			this.$refs.grid[this.varGroupKey].editMeasurer();
		},

		//=============

		// Apresentação =============

		goFullscreen() {
			const element = document.documentElement
			if (element.requestFullscreen) element.requestFullscreen();
			else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen(); /* Safari */
			else element.msRequestFullscreen(); /* IE11 */
		},
		exitFullScreen(){
			if (document.fullscreenElement) 
				document.exitFullscreen()

			this.selectDashboardDisplay('default')
		},
		listenToKeypressfullscreen(e){
			if(e.key == "F11"){
				if(document.fullscreenElement) 
					this.exitFullScreen()
				else 
					document.documentElement.requestFullscreen();
			}
		},
		zoomOut() {
			if (this.zoomLevel > 25) this.zoomLevel = this.zoomLevel - 25
			this.$emit("zoomChange", this.zoomLevel);

		},
		zoomIn() {
			if (this.zoomLevel < 150) this.zoomLevel = this.zoomLevel + 25
			this.$emit("zoomChange", this.zoomLevel);
		},
		resetZoom() {
			this.zoomLevel = 100
		},
		toggleOpenFullscreenButtons(){
			var buttons = document.querySelector(".dashboardFullScreenButtons")
			var indicator = document.querySelector(".fullscreenIndicator")
			buttons.classList.toggle('open')
			indicator.classList.toggle('open')
		},

		//=============

		async reloadItems() {
			await this.service
				.searchByUserId(this.id, this.$session.get('logged_id'))
				.then(async (dashboard) => {
					this.dashboard = dashboard;
					this.$session.set("currentDashboard", dashboard)
					this.groups = dashboard.groups;
					this.groupsKey += 1;
				});
		},

		getVariableData(){
			this.dataService.listValueByDashboardId(this.id).then(res => {
				this.dataValues = res
			})
		},

		searchVariableValueById(varId){
			const map = new Map();
			this.dataValues.forEach((obj) => {
				map.set(obj.id, obj);
			});
	
			//console.log(map);
			let item = map.get(varId)
			//console.log(item);
			if(item){
				var value = parseFloat(item.value.replace(',', '.')) 
				var time = item.datetime
				return {value, time}
			}

			return {time: '', value: 0}

		},

		//=============



		alarmActive(topic){
			if(this.activeAlarmList.indexOf(topic) == -1){
				this.activeAlarmList.push(topic)
			}

		},
		removeAlarm(topic){
			this.activeAlarmList = this.activeAlarmList.filter(item => item != topic)
		},
		hideDashboardDisplayToggleButton(){
			clearTimeout(this.isMouseMovingTimeout)
			this.isMouseMoving = true
			this.isMouseMovingTimeout = setTimeout(() => {
				this.isMouseMoving = false
			}, 5000)	
		}

	},


	async mounted() {
		console.log(this.id)
		this.getTime()
		this.timeUpdateInterval = setInterval(()=>{
			this.getTime()
		}, 1000)
		this.isLoading = true
		// Verifica se existe um dashboard com esse id
		const avaliableDashboards = this.$session.get('user').dashboards.map(dashboard => dashboard.id)
		if (!avaliableDashboards.includes(parseInt(this.id))) {
			this.$router.push('/dashboard').catch(err => { });
			return
		}

		this.service = new this.$dashboardService();
		this.deviceService = new this.$deviceService();
		this.measurerService = new this.$measurerService();
		this.messageController = new this.$messageController();
		this.groupService = new this.$groupService();
		this.messageService = new this.$messageService();
		this.dashboardDisplayService = new this.$dashboardDisplayService();
		this.groupDisplayService = new this.$groupDisplayService();
		this.dataService = new this.$dataService()

		await this.dashboardDisplayService.searchByDashboardId(this.id).then((res) => {
			console.log(res)
			let defaultDisplay = res.find(i => i.name == 'Default')
			if(defaultDisplay) defaultDisplay.id = 'default'	
			this.dashboardDisplayList = res.map(item => {
				item.zoom = item.zoom / 100
				return item
			})

		})

		
		await this.reloadItems();
		
		this.getVariableData()
		
		if(this.initialDashboardDisplay != 0) this.selectDashboardDisplay(this.initialDashboardDisplay)
		this.isLoading = false

		this.reloadVariableDataInterval = setInterval(this.getVariableData,1000)

		this.isShowingAllIcons = true
		document.addEventListener("mousemove", this.hideDashboardDisplayToggleButton)
		document.addEventListener("fullscreenchange", (e) => {
			this.isFullscreen = !this.isFullscreen
			this.$emit("toggleFullscreen", this.isFullscreen)
			document.getElementById("dashboardArea").style.zoom = 1
			if(!this.isFullscreen){
				this.selectDashboardDisplay('default')
			}
			this.zoomLevel = 100
		})

		document.addEventListener("keyup", this.listenToKeypressfullscreen)


		if (this.fullscreen) {
			this.isFullscreen = !this.isFullscreen
			this.zoomLevel = this.zoom;
		}
	},

	watch: {
		zoomLevel() {
			const level = this.zoomLevel / 100
			const controller = document.querySelector('.fullscreenIndicatorContainer')
			const clock = document.querySelector('.timeContainer')


			document.body.style.zoom = level
			document.body.style.setProperty('--zoom', level)
			if(controller){
				controller.style.zoom = 1/level
			}
			if(clock){
				clock.style.zoom = 1/level
			}

		},

		activeAlarmList(){
			if(this.activeAlarmList.length > 0){
				if(this.activeAlarmAudioInterval == 0){
					this.activeAlarmAudioInterval = setInterval(() => {
						var audio = new Audio('../alarm.mp3')
						audio.play()
					}, 1000)
				}
			}
			else{
				clearInterval(this.activeAlarmAudioInterval)
				this.activeAlarmAudioInterval = 0
			}
		},
	},

	beforeDestroy() {
		document.body.style.zoom = 1
		document.removeEventListener("mousemove", this.hideDashboardDisplayToggleButton)
		clearInterval(this.timeUpdateInterval)
		clearInterval(this.activeAlarmAudioInterval)
		clearInterval(this.reloadVariableDataInterval)
		clearTimeout(this.isMouseMovingTimeout)

	}
}